import planLevels from '@/modules/plans/data/planLevels'

export default [
  {
    id: 1,
    type: 'light',
    priority_level: planLevels.light,
    description: {
      text: 'Indicado para quem busca informações isentas sobre insumos',
      highlight: 'informações',
    },
    price: { month: 9.9 },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de cultivares de soja e trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulação de fungicidas para soja e trigo',
        included: false,
      },
      {
        id: 5,
        text: 'Comparação de fungicidas',
        included: false,
      },
      {
        id: 6,
        text: 'Eficácias de fungicidas',
        included: false,
      },
      {
        id: 7,
        text: 'Datas ideais de aplicação em tempo real',
        included: false,
      },
      {
        id: 8,
        text: 'Acompanhamento na produtividade em +3 sc/ha',
        included: false,
      },
      {
        id: 9,
        text: 'Integração com FieldView',
        included: false,
      },
      {
        id: 10,
        text: 'Relatórios personalizados',
        included: false,
      },
    ],
    observations: ['Disponibilizamos 1 usuário, sem acessos simultâneos'],
  },
  {
    id: 2,
    type: 'pro',
    priority_level: planLevels.pro,
    description: {
      text: 'Indicado para quem quer diminuir custo na compra de insumos',
      highlight: 'diminuir custo',
    },
    price: { month: 299.9 },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de cultivares de soja e trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulação de fungicidas para soja e trigo',
        included: true,
      },
      {
        id: 5,
        text: 'Comparação de fungicidas',
        included: true,
      },
      {
        id: 6,
        text: 'Eficácias de fungicidas',
        included: true,
      },
      {
        id: 7,
        text: 'Datas ideais de aplicação em tempo real',
        included: true,
      },
      {
        id: 8,
        text: 'Acompanhamento na produtividade em +3 sc/ha',
        included: false,
      },
      {
        id: 9,
        text: 'Integração com FieldView',
        included: false,
      },
      {
        id: 10,
        text: 'Relatórios personalizados',
        included: false,
      },
    ],
    observations: [
      'Disponibilizamos um usuário, com a possibilidade de aquisição de novas contas pelo valor de R$120,00 por usuário por mês',
    ],
  },
  {
    id: 3,
    type: 'full',
    client: 'pronaf',
    priority_level: planLevels.full,
    description: {
      text: 'Indicado para quem quer otimizar sua operação e aumentar sua produtividade',
      highlight: 'otimizar sua operação',
    },
    price: {
      month: 139.9,
      monthlyHectare: 0.5,
    },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de cultivares de soja e trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulação de fungicidas para soja e trigo',
        included: true,
      },
      {
        id: 5,
        text: 'Comparação de fungicidas',
        included: true,
      },
      {
        id: 6,
        text: 'Eficácias de fungicidas',
        included: true,
      },
      {
        id: 7,
        text: 'Datas ideais de aplicação em tempo real',
        included: true,
      },
      {
        id: 8,
        text: 'Acompanhamento na produtividade em +3 sc/ha',
        included: true,
      },
      {
        id: 9,
        text: 'Integração com FieldView',
        included: true,
      },
      {
        id: 10,
        text: 'Relatórios personalizados',
        included: true,
      },
    ],
    observations: [
      'Obrigatória a apresentação da DAP (Declaração de Aptidão ao Pronaf)',
      'Fidelidade mínima: 1 ano',
      'Disponibilizamos um usuário, com a possibilidade de aquisição de novas contas pelo valor de R$120,00 por usuário por mês',
    ],
  },
  {
    id: 4,
    type: 'full',
    client: 'large_farms',
    priority_level: planLevels.full,
    description: {
      text: 'Indicado para quem quer otimizar sua operação e aumentar sua produtividade',
      highlight: 'otimizar sua operação',
    },
    price: {
      month: 209.9,
      monthlyHectare: 0.5,
    },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de cultivares de soja e trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulação de fungicidas para soja e trigo',
        included: true,
      },
      {
        id: 5,
        text: 'Comparação de fungicidas',
        included: true,
      },
      {
        id: 6,
        text: 'Eficácias de fungicidas',
        included: true,
      },
      {
        id: 7,
        text: 'Datas ideais de aplicação em tempo real',
        included: true,
      },
      {
        id: 8,
        text: 'Acompanhamento na produtividade em +3 sc/ha',
        included: true,
      },
      {
        id: 9,
        text: 'Integração com FieldView',
        included: true,
      },
      {
        id: 10,
        text: 'Relatórios personalizados',
        included: true,
      },
    ],
    observations: [
      'Fidelidade mínima: 1 ano',
      'Disponibilizamos um usuário, com a possibilidade de aquisição de novas contas pelo valor de R$120,00 por usuário por mês',
    ],
  },
]
